import React from 'react'
import { Heading, Box, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
    <Box sx={styles.wrapper}>
      <center><h2><a href="https://twitter.com/shakeshoutout">shake shoutout</a></h2>The top three domains won over the last 24 hours on the Handshake network.<br /><br />🏆 https://xn--nqq435c/ 👉 https://數位/<br />888.88 HNS<br />0.00 ETH<br />$11.74<br /><br />🥈 https://credito/<br />450.00 HNS<br />0.00 ETH<br />$5.94<br /><br />🥉 https://e-maya/<br />11.00 HNS<br />0.00 ETH<br />$0.15<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/nbshoutout">nb shoutout</a></h2>The top three domains bought over the last 24 hours in the Namebase marketplace.<br /><br />🏆 https://lovehotels/<br />20,000.00 HNS<br />0.10 ETH<br />$264.14<br /><br />🥈 https://ravipillai/<br />1,355.00 HNS<br />0.01 ETH<br />$17.90<br /><br />🥉 https://arturomoreno/<br />1,090.00 HNS<br />0.01 ETH<br />$14.40<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shake_supply">shake supply</a></h2>The circulating HNS supply against the maximum supply.<br /><br />[ ▉▉▉▉▉▏▁▁▁▁▁▁▁▁▁▁▁ ] 30.95%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakesplit">shake split</a></h2>The progress made toward the next Handshake halving.<br /><br />[ ▉▉▉▉▉▉▉▁▁▁▁▁▁▁▁▁▁ ] 41.0%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakefoss">shake foss</a></h2>The claimed HNS against the total airdrop to FOSS developers. And the new and total claims since the last tweet.<br /><br />[ ▍▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁ ] 2.91% (New: 0 Total: 6539)<br /><br /><br /><br /><br /><br /></center>
		</Box>
	)